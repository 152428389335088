body {
  font-family: Lato;
}

header,
.app-splash h1 {
  font-family: "Baloo 2";
}

.app-splash h1 {
  font-size: 2.5rem;
}

.app-splash h3 {
  margin-bottom: 2rem;
}

.app-splash {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-items: center;
}

.welcome-content {
  margin: 0 auto;
  text-align: center;
}

.welcome-input {
  margin-bottom: 2rem;
}

header {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  height: 70px;
  justify-content: space-between;
}

.project-name,
.column-name {
  display: flex;
  align-items: center;
  padding: 0 2rem;
}

.project-name h2 {
  padding: 0 1rem;
  font-size: 2rem;
}

.edit-project-name {
  display: none;
  cursor: pointer;
}

.edit-column-name {
  visibility: hidden;
  cursor: pointer;
  padding: 1rem;
}

.edit-project-input,
.edit-column-input {
  margin-right: 0.5rem;
}

.project-name:hover .edit-project-name,
.column-name-container:hover .edit-column-name {
  display: block;
  visibility: visible;
}

.card {
  max-width: 345px;
  box-shadow: "0 0 5px rgba(0, 0, 0, 0.15)";
  border-radius: 8px;
  margin: 0.5rem;
  padding: 1rem;
}

.MuiTypography-root.MuiCardHeader-title.MuiTypography-root-h5.MuiTypography-displayBlock {
  font-size: 1.25;
  font-weight: 600;
}

.card:hover {
  cursor: pointer;
}

.column-container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  height: calc(99vh - 70px);
}

.column {
  flex: 0 0 auto;
  border: 1px solid lightgray;
  background-color: white;
  width: 355px;
  height: 98%;
  margin: 0.5%;
}

.column-contents {
  height: 90%;
  overflow: auto;
}

.column-contents-drag {
  background-color: rgb(238, 238, 238);
}

.column-name-container {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 30px;
  text-transform: capitalize;
  font-weight: 700;
  justify-content: space-between;
}

.task-btn-container p,
.add-column-btn p {
  color: hsl(0, 0%, 30%);
}

.red {
  background-color: #f65c51;
}

.yellow {
  background-color: #ffb75e;
}

.blue {
  background-color: #7689f5;
}

.column-first-half {
  display: flex;
  align-items: center;
}

.edit-column-form,
.edit-project-form {
  display: flex;
}

.edit-column-button,
.edit-project-button {
  margin-left: 0.5rem;
}

.modal {
  position: absolute;
  width: 400px;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 32px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modalForm {
  width: 100%;
  padding-bottom: 1rem;
}

.add-column-btn,
.add-task-btn {
  cursor: pointer;
}

.add-column-form {
  display: flex;
  flex-direction: column;
}

.add-column-form > * {
  margin-bottom: 1rem;
}

.add-column-btn {
  margin: 1.5rem;
  text-align: center;
}

.add-column-btn-wrapper {
  margin: 1rem;
}

.add-task-btn {
  margin-top: 0.5rem;
}

.task-btn-container {
  width: 100%;
  text-align: center;
}

.add-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  margin-left: 1%;
}

.text-center {
  text-align: center;
}

.badge-image {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  max-height: 200px;
}

.badge-image img {
  max-height: 200px;
}

.badge-modal-header {
  font-family: "Baloo 2";
  font-size: 2.25rem;
}

.badge-text {
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.9);
}

.hidden {
  display: none;
}

.column-badge {
  background-color: rgba(0, 0, 0, 0.2);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  line-height: 1rem;
}

.progress-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progress-number {
  margin: 0 1%;
  line-height: 1em;
}

.progress-bar-container {
  width: 300px;
  height: 1.5em;
  background-color: gray;
  border-radius: 1em;
  position: relative;
}

.progress-number {
  padding-bottom: 2%;
}

.progress {
  position: absolute;
  height: 1em;
  top: 0.25em;
  bottom: 0.25em;
  width: 290px;
  left: 5px;
  right: 5px;
  background-color: lightgray;
  border-radius: 0.75em;
}

.card-image {
  width: 100%;
  object-fit: contain;
}

.badge-container img {
  width: 45px;
  margin-right: 1rem;
}

.right-nav {
  display: flex;
  align-items: center;
  margin: 0 0.5rem;
}

/* Slider Component*/

.tutorial-container {
  position: absolute;
  z-index: 2;
  height: 100vh;
  width: 100%;
  background-color: transparent;
}

.inner-tutorial-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-container {
  width: 90%;
  height: 90%;
  display: flex;
  background-color: lightgrey;
  margin: auto;
  align-items: center;
  padding: 0 1%;
  position: relative;
}

.slider {
  width: 90%;
  margin: auto;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.slide {
  min-width: 100%;
  height: 90%;
  transition: 0.5s;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.banner-img {
  object-fit: contain;
  width: 100%;
}

.dots-container {
  position: absolute;
  bottom: 5%;
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  width: 20px;
  height: 20px;
  padding: 5px;
  margin-right: 5px;
  cursor: pointer;
  border-radius: 50%;
  background-color: white;
}

.active {
  background-color: darkgrey;
}

.tutorial-img {
  object-fit: contain;
  width: 90%;
  height: 60%;
}

.tutorial-button {
  position: absolute;
  top: 0;
  right: 0;
}

.photo-input {
  margin-bottom: 1rem;
}

/* MOBILE RESPONSIVENESS */

/* Small devices (tablets, 768px and up) */
@media (max-width: 767px) and (orientation: portrait) {
  header {
    flex-direction: column;
    height: 100px;
    justify-content: center;
    align-items: center;
    padding: 3%;
  }

  .project-name > h2 {
    margin: 0;
  }

  .welcome-content {
    padding: 3%;
  }

  .column-container {
    flex-wrap: wrap;
    margin-top: 1%;
  }

  .column {
    width: 95%;
    margin: auto;
    margin-top: 2%;
    height: auto;
  }

  .column-contents {
    padding-bottom: 2%;
  }

  .add-col {
    width: 100%;
    margin-top: 5%;
  }

  .add-column-btn {
    margin: 0;
  }
  .card {
    max-width: 95%;
    margin: 0.5rem;
  }

  .tutorial-img {
    object-fit: contain;
    width: 90%;
    height: 40%;
  }

  .slide > p {
    font-size: 0.8rem;
  }

  .dots-container {
    position: absolute;
    bottom: 5%;
    width: 90%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .circle {
    width: 10px;
    height: 10px;
    padding: 3px;
    margin-right: 3px;
    cursor: pointer;
    border-radius: 50%;
    background-color: white;
  }

  .active {
    background-color: darkgrey;
  }

  .slide > h1 {
    font-size: 1rem;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .column {
    width: 275px;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (max-width: 992px) and (orientation: landscape) {
  .column {
    height: 95%;
  }
}
